<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefanía Villada Chavarría                                   ###### -->
<!-- ###### @date: Julio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <!-- Menú para seleccionar el tipo de restricción -->
    <v-card class="elevation-0">
      <v-tabs v-model="tipoRestriccion" background-color="white" fixed-tabs height="28">
        <v-tabs-slider color="primary" class="slider"></v-tabs-slider>
        <v-tab> Contraindicación </v-tab>
        <v-tab> Duplicidad </v-tab>
        <v-tab> Restricción </v-tab>
      </v-tabs>
    </v-card>

    <v-tabs-items v-model="tipoRestriccion" class="elevation-2">
      <!-- Contraindicaciones -->
      <v-tab-item>
        <v-card-text>
          <div class="d-flex">
            <!-- Filtro de código 1 -->
            <v-text-field color="primary" class="me-2" v-model="buscar.contraindicacion.codigoUno" label="Código 1"
              dense outlined hide-details @keypress="soloNumeros" @paste="pegarNumeros($event, 'contraindicacion', 'codigoUno')">
            </v-text-field>

            <!-- Filtro del código 2 -->
            <v-text-field color="primary" class="me-2" v-model="buscar.contraindicacion.codigoDos" label="Código 2"
              dense outlined hide-details @keypress="soloNumeros" @paste="pegarNumeros($event, 'contraindicacion', 'codigoDos')">
            </v-text-field>

            <!-- Botón para agregar una contraindicación -->
            <v-tooltip left color="success" :open-on-focus="false">
              <template v-slot:activator="{ attrs, on }">
                <v-btn small fab color="success" v-bind="attrs" v-on="on"
                  @mousedown.prevent="dialogoCombinacion = true">
                  <v-icon> add </v-icon>
                </v-btn>
              </template>
              <span>Agregar</span>
            </v-tooltip>
          </div>

          <!-- Tabla que lista las contraindicaciones -->
          <v-data-table class="elevation mt-4" :items="tabla.contraindicaciones.items" fixed-header hover
            :headers="tabla.contraindicaciones.headers" :loading="tabla.contraindicaciones.loading"
            :page.sync="tabla.contraindicaciones.page" :server-items-length="tabla.contraindicaciones.totalElements"
            :footer-props="tabla.contraindicaciones.footerProps" no-data-text="No se encontraron resultados"
            :items-per-page.sync="tabla.contraindicaciones.itemsPerPage" height="54vh">
            <!-- Columna de el estado(eliminado)  -->
            <template v-slot:[`item.eliminado`]="{ item }">
              <v-icon :color="item.eliminado === false ? 'success' : 'error'">
                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
              </v-icon>
              {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
            </template>
            <!-- Columna de las acciones -->
            <template v-slot:[`item.acciones`]="{ item }">
              <!-- Opción cambiar estado de una contraindicación -->
              <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'success'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="abrirDialogoEstado(item)">
                    <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                      {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
              </v-tooltip>

              <!-- Opción editar una contraindicación -->
              <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'gray'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="item.eliminado === false && abrirDialogoEditar(item)">
                    <v-icon :color="item.eliminado === false ? 'orange' : 'gray'">border_color
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ item.eliminado === false ? 'Editar' : 'Registro inactivo' }}</span>
              </v-tooltip>
            </template>
            <template v-slot:footer.page-text="items">
              {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-tab-item>

      <!-- Duplicidad -->
      <v-tab-item>
        <v-card-text>
          <div class="d-flex">
            <!-- Filtro por código 1 -->
            <v-text-field color="primary" class="me-2" v-model="buscar.duplicidad.codigoUno" label="Código 1" 
              dense outlined hide-details @keypress="soloNumeros" @paste="pegarNumeros($event, 'duplicidad', 'codigoUno')">
            </v-text-field>

            <!-- Filtro por código 2 -->
            <v-text-field color="primary" class="me-2" v-model="buscar.duplicidad.codigoDos" label="Código 2"
              dense outlined hide-details @keypress="soloNumeros" @paste="pegarNumeros($event, 'duplicidad', 'codigoDos')">
            </v-text-field>

            <!-- Botón para agregar una duplicidad -->
            <v-tooltip left color="success" :open-on-focus="false">
              <template v-slot:activator="{ attrs, on }">
                <v-btn small fab color="success" v-bind="attrs" v-on="on"
                  @mousedown.prevent="dialogoCombinacion = true">
                  <v-icon> add </v-icon>
                </v-btn>
              </template>
              <span>Agregar</span>
            </v-tooltip>
          </div>

          <!-- Tabla que lista las duplicidades -->
          <v-data-table class="elevation mt-4" :items="tabla.duplicidad.items" fixed-header hover
            :headers="tabla.duplicidad.headers" :loading="tabla.duplicidad.loading"
            no-data-text="No se encontraron resultados" :page.sync="tabla.duplicidad.page"
            :server-items-length="tabla.duplicidad.totalElements" :footer-props="tabla.duplicidad.footerProps"
            :items-per-page.sync="tabla.duplicidad.itemsPerPage" height="54vh">
            <!-- Columna de estado(eliminado) -->
            <template v-slot:[`item.eliminado`]="{ item }">
              <v-icon :color="item.eliminado === false ? 'success' : 'error'">
                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
              </v-icon>
              {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
            </template>
            <!-- Columna de las acciones -->
            <template v-slot:[`item.acciones`]="{ item }">
              <!-- Opción para cambiar el estado de una duplicidad -->
              <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'success'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="abrirDialogoEstado(item)">
                    <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                      {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
              </v-tooltip>

              <!-- Opción para editar una duplicidad -->
              <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'gray'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="item.eliminado === false && abrirDialogoEditar(item)">
                    <v-icon :color="item.eliminado === false ? 'orange' : 'gray'">border_color
                    </v-icon>
                  </v-btn>
                </template>
                <span class="text-center">{{ item.eliminado === false ? 'Editar' : 'Registro inactivo' }}</span>
              </v-tooltip>
            </template>
            <template v-slot:footer.page-text="items">
              {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-tab-item>

      <!-- Restricciones -->
      <v-tab-item>
        <v-card-text>
          <div class="d-flex">
            <!-- Filtro por código -->
            <v-text-field color="primary" class="me-2" v-model="buscar.restriccion.codigo" label="Código" dense outlined
              hide-details @keypress="soloNumeros" @paste="pegarNumeros($event, 'restriccion', 'codigo')">
            </v-text-field>

            <!-- Botón para agregar una nueva restricción -->
            <v-tooltip left color="success" :open-on-focus="false">
              <template v-slot:activator="{ attrs, on }">
                <v-btn small fab color="success" v-bind="attrs" v-on="on"
                  @mousedown.prevent="dialogoRestriccion = true">
                  <v-icon> add </v-icon>
                </v-btn>
              </template>
              <span>Agregar</span>
            </v-tooltip>
          </div>

          <!-- Tabla que lista las restricciones -->
          <v-data-table class="elevation mt-4" :items="tabla.restricciones.items" fixed-header hover
            :headers="tabla.restricciones.headers" :loading="tabla.restricciones.loading" :page.sync="tabla.restricciones.page"
            :server-items-length="tabla.restricciones.totalElements" :footer-props="tabla.restricciones.footerProps"
            :items-per-page.sync="tabla.restricciones.itemsPerPage" height="54vh"
            no-data-text="No se encontraron resultados">
            <!-- Columna de los diagnósticos -->
            <template v-slot:[`item.diagnosticos`]="{ item }">
              <!-- Opción para parametrizar los diagnósticos a la restricción -->
              <v-tooltip bottom color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="abrirDialogoDiagnosticos(item)">
                    <v-icon color="success">playlist_add</v-icon>
                  </v-btn>
                </template>
                <span>Parametrizar diagnósticos</span>
              </v-tooltip>

              <!-- Opción para ver los diagnósticos parametrizados a la restricción -->
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="abrirDialogoRestriccionCie(item)">
                    <v-icon color="primary">visibility</v-icon>
                  </v-btn>
                </template>
                <span>Ver diagnósticos</span>
              </v-tooltip>
            </template>
            <!-- Columna de los detalles -->
            <template v-slot:[`item.detalles`]="{ item }">
              <v-tooltip bottom color="blue">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="abrirDialogoDetalles(item)">
                    <v-icon color="blue">library_add</v-icon>
                  </v-btn>
                </template>
                <span>Ver detalles</span>
              </v-tooltip>
            </template>
            <!-- Columna del estado(eliminado) -->
            <template v-slot:[`item.eliminado`]="{ item }">
              <v-icon :color="item.eliminado === false ? 'success' : 'error'">
                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
              </v-icon>
              {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
            </template>
            <!-- Columna de las acciones -->
            <template v-slot:[`item.acciones`]="{ item }">
              <!-- Opción para cambiar el estado de una restricción -->
              <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'success'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="abrirDialogoEstado(item)">
                    <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                      {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
              </v-tooltip>

              <!-- Opción para ediatar una restricción -->
              <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'gray'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="item.eliminado === false && abrirDialogoEditar(item)">
                    <v-icon :color="item.eliminado === false ? 'orange' : 'gray'">border_color
                    </v-icon>
                  </v-btn>
                </template>
                <span class="text-center">{{ item.eliminado === false ? 'Editar' : 'Registro inactivo' }}</span>
              </v-tooltip>
            </template>
            <template v-slot:footer.page-text="items">
              {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>

    <!-- Dilogo para crear o editar una contraindicación o una duplicidad -->
    <v-dialog v-model="dialogoCombinacion" transition="dialog-bottom-transition" max-width="35rem" :editar="false" persistent>
      <v-card>
        <v-card-title class="encabezado"> {{ tituloDialogo }} </v-card-title>
        <v-card-text class="pt-6">
          <v-form ref="crearCombinacion">
            <!-- Campo para ingresar el primer producto -->
            <v-autocomplete v-model="crearCombinacion.idPrimerProducto" :items="productosUno" @update:search-input="listarProductosUno"
              dense outlined label="Primer producto" no-data-text="No se encontraron resultados" :rules="[rules.required]"
              :menu-props="{ offsetY: true, maxHeight: 200 , maxWidth: 512 }" color="primary" @change="validarExistencia()">
            </v-autocomplete>

            <!-- Campo para ingresar el segundo producto -->
            <v-autocomplete v-model="crearCombinacion.idSegundoProducto" :items="productosDos" @update:search-input="listarProductosDos"
              dense outlined label="Segundo producto" no-data-text="No se encontraron resultados" :rules="[rules.required]" @change="validarExistencia()"
              :menu-props="{ offsetY: true, maxHeight: 200 , maxWidth: 512 }" color="primary" :error-messages="validateProducto || validateCodigos">
            </v-autocomplete>

            <!-- Campo para ingresar la descripcion -->
            <v-textarea id="textarea" v-model="crearCombinacion.observacion" rows="3" dense outlined label="Descripción" maxlength="4096"
              counter no-resize :rules="[rules.required]" color="primary" @keypress="caracteresPermitidos" @paste="pasteDescripcion">
            </v-textarea>
          </v-form>
          <div class="d-flex justify-end">
            <v-btn class="me-2" text color="error" @click="clear()">CERRAR</v-btn>
            <v-btn depressed :color="!formCombinacion ? 'gray' : 'success'" @click="!editar ? agregarCombinacion() : editarCombinacion()" :disabled="!formCombinacion">GUARDAR</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para cambiar el estado de una contraindicación, duplicidad o una restricción -->
    <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6">{{ mensajeEstado }}</span>
        </v-card-title>
        <v-card-text class="pt-6">
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
            <v-btn color="success" depressed text @click="cambiarEstado()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para crear una restricción -->
    <v-dialog v-model="dialogoRestriccion" transition="dialog-bottom-transition" max-width="40rem" persistent :editar="false">
      <v-card>
        <v-card-title class="encabezado">{{ tituloDialogo }}</v-card-title>
        <v-card-text class="pt-6">
          <v-form ref="crearRestriccion">
            <!-- Campo para ingresar el producto -->
            <v-autocomplete v-model="crearRestriccion.producto" :items="productosUno" @update:search-input="listarProductosUno"
              dense outlined label="Producto" no-data-text="No se encontraron resultados" :rules="[rules.required]" @change="validarExistencia()"
              :menu-props="{ offsetY: true, maxHeight: 200 , maxWidth: 592 }" color="primary" :error-messages="validateCodigos">
            </v-autocomplete>

            <!-- Campo para ingresar la restricción -->
            <v-textarea id="textarea" v-model="crearRestriccion.restriccion" rows="2" dense outlined label="Restricción" maxlength="4096"
              counter no-resize :rules="[rules.required]" color="primary" @keypress="caracteresPermitidos" @paste="pasteDescripcion">
            </v-textarea>

            <div class="d-flex">
              <!-- Campo para ingresar si requiere mipres -->
              <v-autocomplete class="me-2 campo" v-model="crearRestriccion.mipres" label="¿Requiere MIPRES?" dense outlined :items="opciones" color="primary"
                :menu-props="{ offsetY: true, maxHeight: 200 , maxWidth: 512 }" :rules="[rules.required]" no-data-text="No se encontraron resultados">
              </v-autocomplete>

              <!-- Campo para ingresar si requiere autorización del pafi -->
              <v-autocomplete class="campo" v-model="crearRestriccion.pafi" label="¿Requiere autorización del PAFI?" dense outlined :items="opciones" color="primary"
                :menu-props="{ offsetY: true, maxHeight: 200 , maxWidth: 512 }" :rules="[rules.required]" no-data-text="No se encontraron resultados">
              </v-autocomplete>
            </div>

            <div class="d-flex">
              <!-- Campo para ingresar el género -->
              <v-autocomplete class="me-2 campo" v-model="crearRestriccion.genero" label="Género" dense outlined :items="genero" color="primary"
                :menu-props="{ offsetY: true, maxHeight: 200 , maxWidth: 512 }" no-data-text="No se encontraron resultados">
              </v-autocomplete>

              <!-- Campo para ingresar las unidades maximas por mes -->
              <v-text-field class="campo" v-model="crearRestriccion.unidadesMaximas" label="Unidades máximas por mes" dense outlined color="primary"
                @keypress="soloNumeros" @paste.prevent>
              </v-text-field>
            </div>

            <div class="d-flex">
              <!-- Campo para ingresar la edad minima en meses -->
              <v-text-field class="me-2 campo" v-model="crearRestriccion.edadMinima" label="Edad mínima (meses)" dense outlined color="primary" 
                @keypress="soloNumeros" @paste.prevent @input="sincronizarEdadMinima">
              </v-text-field>

              <!-- Campo para ingresar la edad minima en años -->
              <v-text-field class="campo" v-model="edadMinima" label="Edad mínima (años)" dense outlined color="primary"
                @input="sincronizarEdadMinimaAnios" @keypress="soloNumeros" @paste.prevent>
              </v-text-field>
            </div>

            <div class="d-flex">
              <!-- Campo para ingresar la edad maxima en meses -->
              <v-text-field class="me-2 campo" v-model="crearRestriccion.edadMaxima" label="Edad máxima (meses)" dense outlined color="primary"
                @keypress="soloNumeros" @paste.prevent @input="sincronizarEdadMaxima">
              </v-text-field>

              <!-- Campo para ingresar la edad maxima en años -->
              <v-text-field class="campo" v-model="edadMaxima" label="Edad máxima (años)" dense outlined color="primary"
                @input="sincronizarEdadMaximaAnios" @keypress="soloNumeros" @paste.prevent>
              </v-text-field>
            </div>
          </v-form>
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="clear()">CERRAR</v-btn>
            <v-btn depressed :color="!formRestriccion ? 'gray' : 'success'" @click="!editar ? agregarRestriccion() : editarRestriccion()" :disabled="!formRestriccion">GUARDAR</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para ver los detalles de la restricción seleccionada -->
    <v-dialog v-model="dialogoDetalles" transition="dialog-bottom-transition" max-width="25rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6">Detalles</span>
        </v-card-title>
        <v-card-text class="pt-6 black--text">
          <div v-if="restriccionSeleccionada.edadMinima !== null">
            <span class="font-weight-black">Edad mínima: </span>
            {{ restriccionSeleccionada.edadMinima }} meses {{ convertirMeses(restriccionSeleccionada.edadMinima) }}
          </div>
          <div v-if="restriccionSeleccionada.edadMaxima !== null">
            <span class="font-weight-black">Edad máxima: </span>
            {{ restriccionSeleccionada.edadMaxima }} meses {{ convertirMeses(restriccionSeleccionada.edadMaxima) }}
          </div>
          <div v-if="restriccionSeleccionada.unidadesMaximaMes !== null">
            <span class="font-weight-black">Unidades máximas por mes: </span>{{ restriccionSeleccionada.unidadesMaximaMes }}
          </div>
          <div v-if="restriccionSeleccionada.genero !== null">
            <span class="font-weight-black">Género: </span>{{ restriccionSeleccionada.generoValue === 'M' ? 'Masculino' : 'Femenino' }}
          </div>
          <div v-if="restriccionSeleccionada.requiereMipres !== ''">
            <span class="font-weight-black">¿Requiere MIPRES?: </span>{{ restriccionSeleccionada.requiereMipres ? 'Si' : 'No' }}
          </div>
          <div v-if="restriccionSeleccionada.autorizaPafi !== ''">
            <span class="font-weight-black">¿Requiere autorización del PAFI?: </span>{{ restriccionSeleccionada.autorizaPafi ? 'Si' : 'No' }}
          </div>
          <div class="d-flex justify-end">
            <v-btn color="error" text @click="dialogoDetalles = false">Cerrar</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para crear los diagnósticos relacionados a una restricción -->
    <v-dialog v-model="dialogoDiagnosticos" transition="dialog-bottom-transition" max-width="45rem" persistent>
      <v-card>
        <v-card-title class="encabezado">Parametrizar diagnósticos</v-card-title>
        <v-card-text class="pt-6">
          <div class="d-flex black--text"><div class="font-weight-black me-2">PRODUCTO: </div>{{ producto }}</div>
          
          <!-- Campo para seleccionar el tipo de diagnóstico -->
          <v-radio-group class="ma-0 mt-4" v-model="tipoDiagnostico" row label="Seleccione tipo de diagnóstico: ">
            <v-radio label="Permitidos" value="P" ></v-radio>
            <v-radio label="Restrictivos" value="R" ></v-radio>
          </v-radio-group>

          <!-- Campo para seleccionar el diagnóstico -->
          <v-autocomplete ref="buscarCie" class="mb-6" v-model="selectedItem" outlined dense label="Buscar diagnósticos" :items="diagnosticos" 
            @update:search-input="listarCodigosCie" hide-details return-object @change="agregarDiagnostico" 
            :menu-props="{ maxWidth: 672, maxHeight: 250, offsetY: true}" no-data-text="No se encontraron resultados">
          </v-autocomplete>
          
          <!-- Campo para almacenar los diagnósticos seleccionados -->
          <span>Diagnósticos seleccionados: </span>
          <v-card outlined class="contenedor-lista">
            <v-list dense class="lista">
              <v-list-item v-for="(item, index) in selectedItems" :key="index">
                <v-list-item-content> {{ item.text }}</v-list-item-content>
                <v-list-item-action class="ma-0">
                  <v-btn icon @click="quitarDiagnostico(index)">
                    <v-icon color="red">close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>

          <span v-if="errorCodigos.length" class="error--text">
            Estos diagnósticos ya se encuentran parametrizados: {{ errorCodigosConcatenados }}.
          </span>
          <div class="d-flex justify-end">
            <v-btn class="me-2" text color="red" @click="clear()">Cerrar</v-btn>
            <v-btn color="success" depressed :disabled="selectedItems.length < 1 || errorCodigos.length > 0" @click="agregarRestriccionCie()">Guardar</v-btn>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para ver los diagnósticos de una restricción -->
     <v-dialog v-model="dialogoRestriccionCie" transition="dialog-bottom-transition" max-width="55rem" persistent>
      <v-card>
        <v-card-title class="encabezado">Diagnósticos del producto {{ producto }}</v-card-title>
        <v-card-text class="pt-6">
          <!-- Campo para seleccionar el tipo de diagnostico -->
          <v-radio-group class="ma-0" v-model="buscar.cie.tipoDiagnostico" row hide-details @change="listarRestriccionCie()">
            <v-radio label="Permitidos" value="P" ></v-radio>
            <v-radio label="Restrictivos" value="R" ></v-radio>
          </v-radio-group>

          <v-data-table class="elevation mt-4" :items="tabla.restriccionCie.items" fixed-header hover
            :headers="tabla.restriccionCie.headers" :loading="tabla.restriccionCie.loading" :page.sync="tabla.restriccionCie.page"
            :server-items-length="tabla.restriccionCie.totalElements" :footer-props="tabla.restriccionCie.footerProps"
            :items-per-page.sync="tabla.restriccionCie.itemsPerPage" height="45vh"
            no-data-text="No se encontraron resultados">
            <!-- Columna tipo de diagnóstico -->
            <template v-slot:[`item.estadoCie`]="{ item }">
              {{ item.estadoCie === 'P' ? 'Permitidos' : 'Restrictivos' }}
            </template>
            <!-- Columna del estado(eliminado) -->
            <template v-slot:[`item.eliminado`]="{ item }">
              <v-icon :color="item.eliminado === false ? 'success' : 'error'">
                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
              </v-icon>
              {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
            </template>
            <!-- Columna de las acciones -->
            <template v-slot:[`item.acciones`]="{ item }">
              <!-- Opción para cambiar el estado de restricción cie -->
              <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'success'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="abrirDialogoEstadoRestriccionCie(item)">
                    <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                      {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
              </v-tooltip>

              <!-- Opción para editar una restricción cie -->
              <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'gray'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="item.eliminado === false && abrirDialogoEditarRestriccionCie(item)">
                    <v-icon :color="item.eliminado === false ? 'orange' : 'gray'">border_color
                    </v-icon>
                  </v-btn>
                </template>
                <span class="text-center">{{ item.eliminado === false ? 'Editar' : 'Registro inactivo' }}</span>
              </v-tooltip>
            </template>
            <template v-slot:footer.page-text="items">
              {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
          </v-data-table>
          <div class="d-flex justify-end">
            <v-btn text color="error" @click="clear()">CERRAR</v-btn>
          </div>
        </v-card-text>
      </v-card>
     </v-dialog>

     <!-- Dialogo para cambiar el estado de una contraindicación, duplicidad o una restricción -->
    <v-dialog v-model="dialogoEstadoRestriccionCie" transition="dialog-bottom-transition" max-width="23rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6">{{ estadoRestriccionCie }}</span>
        </v-card-title>
        <v-card-text class="pt-6">
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="dialogoEstadoRestriccionCie = false">No</v-btn>
            <v-btn color="success" depressed text @click="cambiarEstadoRestriccionCie()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para editar un diagnóstico parametrizado a una restricción -->
    <v-dialog v-model="dialogoEditarRestriccionCie" transition="dialog-bottom-transition" max-width="30rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6">Editar</span>
        </v-card-title>
        <v-card-text class="pt-6">
          <v-form ref="editarRestriccionCie">
            <!-- Campo para editar el tipo de diagnóstico -->
            <v-autocomplete v-model="editarRestriccionCie.tipo" outlined dense :items="tipoCie" label="Tipo diagnóstico" :rules="[rules.required]"
              :menu-props="{ offsetY: true, maxHeight: 200 , maxWidth: 512 }" no-data-text="No se encontraron resultados">
            </v-autocomplete>

            <!-- Campo para editar el diagnóstico -->
            <v-autocomplete v-model="editarRestriccionCie.codigo" label="CIE" :items="diagnosticos" @update:search-input="listarCodigosCie"
              dense outlined no-data-text="No se encontraron resultados" :rules="[rules.required]" :error-messages="validateCodigos"
              :menu-props="{ offsetY: true, maxHeight: 200 , maxWidth: 432 }" color="primary" @input="validarExistenciaCieEditar()">
            </v-autocomplete>
          </v-form>
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="clearEditar()">Cerrar</v-btn>
            <v-btn color="success" :disabled="!formEditar" depressed @click="actualizarRestriccionCie()">Guardar</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";

export default {
  name: 'Restricciones',
  data() {
    return {
      tipoRestriccion: 0,
      tipoDiagnostico: 'P',
      productosUno: [],
      productosDos: [],
      dialogoCombinacion: false,
      dialogoCambiarEstado: false,
      dialogoRestriccion: false,
      dialogoDetalles: false,
      dialogoDiagnosticos: false,
      dialogoRestriccionCie: false,
      dialogoEstadoRestriccionCie: false,
      dialogoEditarRestriccionCie: false,
      combinacionSeleccionada: {},
      restriccionSeleccionada: {},
      restriccionCieSeleccionada: {},
      editar: false,
      buscar: {
        contraindicacion: {
          codigoUno: '',
          codigoDos: '',
        },
        duplicidad: {
          codigoUno: '',
          codigoDos: '',
        },
        restriccion: {
          codigo: '',
          producto: '',
        },
        cie: {
          tipoDiagnostico: 'P'
        }
      },
      tabla: {
        contraindicaciones: {
          loading: false,
          items: [],
          headers: [
            { text: "CÓDIGO 1", value: 'idPrimerProducto.codigoProducto', align: 'center', width: "6%", sortable: false },
            { text: "PRODUCTO 1", value: 'idPrimerProducto.descripcionProducto', width: "20%", sortable: false },
            { text: "CÓDIGO 2", value: 'idSegundoProducto.codigoProducto', align: 'center', width: "6%", sortable: false },
            { text: "PRODUCTO 2", value: 'idSegundoProducto.descripcionProducto', width: "20%", sortable: false },
            { text: "DESCRIPCIÓN", value: 'observacion', width: "25%", sortable: false },
            { text: "ESTADO", value: 'eliminado', width: "10%", sortable: false },
            { text: "ACCIONES", value: 'acciones', width: "10%", sortable: false, align: "center" },
          ],
          footerProps: {
            'items-per-page-options': [10, 20, 30],
            'items-per-page-text': 'Items por página:',
            'show-current-page': true,
            'show-first-last-page': true
          },
          itemsPerPage: 10,
          page: 1,
          totalElements: 0
        },
        duplicidad: {
          loading: false,
          items: [],
          headers: [
            { text: "CÓDIGO 1", value: 'idPrimerProducto.codigoProducto', align: 'center', width: "6%", sortable: false },
            { text: "PRODUCTO 1", value: 'idPrimerProducto.descripcionProducto', width: "20%", sortable: false },
            { text: "CÓDIGO 2", value: 'idSegundoProducto.codigoProducto', align: 'center', width: "6%", sortable: false },
            { text: "PRODUCTO 2", value: 'idSegundoProducto.descripcionProducto', width: "20%", sortable: false },
            { text: "DESCRIPCIÓN", value: 'mensaje', width: "25%", sortable: false },
            { text: "ESTADO", value: 'eliminado', width: "10%", sortable: false },
            { text: "ACCIONES", value: 'acciones', width: "10%", sortable: false, align: "center" },
          ],
          footerProps: {
            'items-per-page-options': [10, 20, 30],
            'items-per-page-text': 'Items por página:',
            'show-current-page': true,
            'show-first-last-page': true
          },
          itemsPerPage: 10,
          page: 1,
          totalElements: 0
        },
        restricciones: {
          loading: false,
          items: [],
          headers: [
            { text: "CÓDIGO", value: 'producto.codigoProducto', align: 'center', width: "10%", sortable: false },
            { text: "PRODUCTO", value: 'producto.descripcionProducto', width: "25%", sortable: false },
            { text: "RESTRICCIÓN", value: 'restriccion', width: "25%", sortable: false },
            { text: "DIAGNÓSTICOS", value: 'diagnosticos', align: 'center', width: "10%", sortable: false },
            { text: "DETALLES", value: 'detalles', align: 'center', width: "10%", sortable: false },
            { text: "ESTADO", value: 'eliminado', width: "10%", sortable: false },
            { text: "ACCIONES", value: 'acciones', align: 'center', width: "10%", sortable: false },
          ],
          footerProps: {
            'items-per-page-options': [10, 20, 30],
            'items-per-page-text': 'Items por página:',
            'show-current-page': true,
            'show-first-last-page': true
          },
          itemsPerPage: 10,
          page: 1,
          totalElements: 0
        },
        restriccionCie: {
          loading: false,
          items: [],
          headers: [
            { text: "CÓDIGO CIE", value: 'cie.codigoCIE', width: "15%", sortable: false },
            { text: "DESCRIPCIÓN", value: 'cie.descripcionCIE', width: "55%", sortable: false },
            { text: "ESTADO", value: 'eliminado', width: "15%", sortable: false },
            { text: "ACCIONES", value: 'acciones', width: "15%", sortable: false },
          ],
          footerProps: {
            'items-per-page-options': [10, 20, 30],
            'items-per-page-text': 'Items por página:',
            'show-current-page': true,
            'show-first-last-page': true
          },
          itemsPerPage: 10,
          page: 1,
          totalElements: 0,
        },
      },
      crearCombinacion: {
        idPrimerProducto: null,
        idSegundoProducto: null,
        observacion: null,
      },
      crearRestriccion: {
        producto: null,
        restriccion: null,
        mipres: null,
        edadMinima: null,
        edadMaxima: null,
        diagnosticosPermitidos: null,
        diagnosticosRestrictivos: null,
        genero: null,
        unidadesMaximas: null,
        pafi: null
      },
      editarRestriccionCie: {
        tipo: null,
        codigo: null
      },
      opciones: [
        { text: 'Si', value: true },
        { text: 'No', value: false }
      ],
      genero: [
        { text: 'Femenino', value: 'F' },
        { text: 'Masculino', value: 'M' }
      ],
      rules: {
        required: (v) => (!!v || v !== null) || "Este campo es requerido."
      },
      validateProducto: '',
      mensajeEstado: '',
      estadoRestriccionCie: '',
      validateCodigos: '',
      edadMaxima: null,
      edadMinima: null,
      producto: '',
      errorCodigos: [],
      tipoCie: [
        { text: 'Permitidos', value: 'P' },
        { text: 'Restringidos', value: 'R' }
      ],
      diagnosticos: [],
      selectedItem: null,
      selectedItems: [],
    }
  },
  mounted() {
    this.listarContraindicaciones();
  },
  computed: {
    ...mapState(["enterprise"]),
    tituloDialogo() {
      if (this.tipoRestriccion === 0) {
        return !this.editar ? 'Agregar contraindicación' : 'Editar contraindicación';
      } else if (this.tipoRestriccion === 1) {
        return !this.editar ? 'Agregar duplicidad': 'Editar duplicidad';
      } else {
        return !this.editar ? 'Agregar restricción' : 'Editar restricción';
      }
    },
    formCombinacion() {
      return this.crearCombinacion.idPrimerProducto && this.crearCombinacion.idSegundoProducto 
      && this.crearCombinacion.observacion && !this.validateProducto && !this.validateCodigos;
    },
    formRestriccion() {
      return this.crearRestriccion.producto && this.crearRestriccion.restriccion && !this.validateProducto && !this.validateCodigos
      && this.crearRestriccion.mipres !== null && this.crearRestriccion.pafi !== null;
    },
    formEditar() {
      return this.editarRestriccionCie.tipo && this.editarRestriccionCie.codigo && !this.validateCodigos;
    },
    errorCodigosConcatenados() {
      return this.errorCodigos.join(', ');
    }
  },
  watch: {
    'crearCombinacion.idPrimerProducto': function () {
      this.validarProductos();
    },
    'crearCombinacion.idSegundoProducto': function () {
      this.validarProductos();
    },
    'tabla.contraindicaciones.page': function () {
      this.listarContraindicaciones();
    },
    'tabla.contraindicaciones.itemsPerPage': function () {
      this.tabla.contraindicaciones.page = 1;
      this.listarContraindicaciones();
    },
    'buscar.contraindicacion.codigoUno': function () {
      this.tabla.contraindicaciones.page = 1;
      this.listarContraindicaciones();
    },
    'buscar.contraindicacion.codigoDos': function () {
      this.tabla.contraindicaciones.page = 1;
      this.listarContraindicaciones();
    },
    'tabla.duplicidad.page': function () {
      this.listarDuplicidad();
    },
    'tabla.duplicidad.itemsPerPage': function () {
      this.tabla.duplicidad.page = 1;
      this.listarDuplicidad();
    },
    'buscar.duplicidad.codigoUno': function () {
      this.tabla.duplicidad.page = 1;
      this.listarDuplicidad();
    },
    'buscar.duplicidad.codigoDos': function () {
      this.tabla.duplicidad.page = 1;
      this.listarDuplicidad();
    },
    'tabla.restricciones.page': function () {
      this.listarRestricciones();
    },
    'tabla.restricciones.itemsPerPage': function () {
      this.tabla.restricciones.page = 1;
      this.listarRestricciones();
    },
    'buscar.restriccion.codigo': function () {
      this.tabla.restricciones.page = 1;
      this.listarRestricciones();
    },
    'tipoDiagnostico': function () {
      this.selectedItems = [];
      this.selectedItem = null;
      if (this.$refs.buscarCie) {
        this.$refs.buscarCie.reset();
      }
      this.errorCodigos = [];
    },
    'tipoRestriccion': function () {
      if (this.tipoRestriccion === 1) {
        this.listarDuplicidad();
      } else if (this.tipoRestriccion === 2) {
        this.listarRestricciones();
      } else {
        this.listarContraindicaciones();
      }
    }
  },
  methods: {
    /**
     * Permite que solo los números ingresen en los campos.
     * @param event - El evento de entrada en los campos de texto.
     */
    soloNumeros(event) {
      const key = event.key;
      if (key.match(/\D/)) {
        event.preventDefault();
      }
    },
    /**
     * Permite que solo ciertos caracteres ingresen al campo.
     * @param event - El evento de entrada en el campo de texto. 
     */
    caracteresPermitidos(event) {
      const key = event.key;
      if (key.match(/[^\w\s.,:ñÑáéíóúÁÉÍÓÚ-]/)) {
        event.preventDefault();
      }
    },
    /**
     * Permite que al pegar un texto solo se peguen ciertos caracteres en específico.
     * @param event - El evento de pegar en el campo de texto.
     */
    pasteDescripcion(event) {
      event.preventDefault();
      
      let textoPegado = (event.clipboardData || window.Clipboard).getData('text');
      let textoLimpio = textoPegado.replace(/[^\w\s.,:ñÑáéíóúÁÉÍÓÚ-]/g, '');
      
      const textarea = event.target;
      const inicio = textarea.selectionStart;
      const fin = textarea.selectionEnd;
      
      textarea.value = textarea.value.slice(0, inicio) + textoLimpio + textarea.value.slice(fin);
      textarea.setSelectionRange(inicio + textoLimpio.length, inicio + textoLimpio.length);

      this.crearCombinacion.observacion = textarea.value
      this.crearRestriccion.restriccion = textarea.value;
    },
    /**
     * Permite solo pegar números
     * @param event - El evento de pegar en el campo de texto.
     * @param tipo - El tipo de restricción (contraindicacion, duplicidad o restriccion)
     * @param campo - El campo (código uno o código dos y para restrcción solo código)
     */
    pegarNumeros(event, tipo, campo) {
      event.preventDefault();
      const clipboardData = event.clipboardData || window.Clipboard;
      const pastedData = clipboardData.getData('text');
      if (!/^\d+$/.test(pastedData)) {
        return;
      }
      this.buscar[tipo][campo] = pastedData;
    },
    /**
     * Lista los productos para el campo idPrimerProducto y el campo producto en las restricciones.
     * @param search - El término de búsqueda ingresado.
     */
    listarProductosUno(search) {
      if (!search) {
        this.productosUno = [];
        return;
      }

      const busqueda = search;
      const numero = !isNaN(busqueda);

      const params = {
        codigoProducto: numero ? busqueda : null,
        descripcion: numero ? null : busqueda,
        idEmpresa: this.enterprise.code,
        page: 0,
        size: 20
      }
      this.$http.get(`msa-setting/api/producto/list`, {
        params
      }).then((response) => {
        if (response.data.body.content.length > 0) {
          response.data.body.content.forEach((item) => {
            this.productosUno.push({
              text: `${item.codigoProducto} - ${item.descripcionProducto}`,
              value: item.idProducto
            });
          });
        } 
      }).catch((error) => {
        console.log(error);
      });
    },
    /**
     * Lista los productos para el campo idSegundoProducto.
     * @param search - El término de búsqueda ingresado.
     */
    listarProductosDos(search) {
      if (!search) {
        this.productosDos = [];
        return;
      }

      const busqueda = search;
      const numero = !isNaN(busqueda);

      const params = {
        codigoProducto: numero ? busqueda : null,
        descripcion: numero ? null : busqueda,
        idEmpresa: this.enterprise.code,
        page: 0,
        size: 20
      }
      this.$http.get(`msa-setting/api/producto/list`, {
        params
      }).then((response) => {
        if (response.data.body.content.length > 0) {
          response.data.body.content.forEach((item) => {
            this.productosDos.push({
              text: `${item.codigoProducto} - ${item.descripcionProducto}`,
              value: item.idProducto
            });
          });
        } 
      }).catch((error) => {
        console.log(error);
      });
    },
    /**
     * Abre el diálogo para cambiar el estado de un registro.
     * @param item - El registro seleccionado.
     */
    abrirDialogoEstado(item) {
      this.combinacionSeleccionada = item;
      this.dialogoCambiarEstado = true;
      this.mensajeEstado = this.combinacionSeleccionada.eliminado === false ? '¿Desea inactivar el registro?' : '¿Desea activar el registro?';
    },
    /**
     * Abre el diálogo para editar un registro.
     * @param item - El registro seleccionado. 
     */
    abrirDialogoEditar(item) {
      this.editar = true;
      if (this.tipoRestriccion !== 2) {
        this.combinacionSeleccionada = item;
        this.crearCombinacion.idPrimerProducto = item.idPrimerProducto.idProducto;
        this.listarProductosUno(item.idPrimerProducto.codigoProducto);
        this.crearCombinacion.idSegundoProducto = item.idSegundoProducto.idProducto;
        this.listarProductosDos(item.idSegundoProducto.codigoProducto);
        this.crearCombinacion.observacion = item.observacion || item.mensaje;
        setTimeout(() => {
          this.dialogoCombinacion = true;
        }, 200);
      } else {
        this.restriccionSeleccionada = item;
        this.listarProductosUno(item.producto.codigoProducto);
        this.crearRestriccion.producto = item.producto.idProducto;
        this.crearRestriccion.restriccion = item.restriccion;
        this.crearRestriccion.mipres = item.requiereMipres;
        this.crearRestriccion.pafi = item.autorizaPafi;
        this.crearRestriccion.genero = item.generoValue;
        this.crearRestriccion.unidadesMaximas = item.unidadesMaximaMes;
        this.crearRestriccion.edadMinima = item.edadMinima;
        this.crearRestriccion.edadMaxima = item.edadMaxima;

        if (this.crearRestriccion.edadMinima) {
          this.edadMinima = Math.floor(this.crearRestriccion.edadMinima / 12);
        }
        if (this.crearRestriccion.edadMaxima) {
          this.edadMaxima = Math.floor(this.crearRestriccion.edadMaxima / 12);
        }
        setTimeout(() => {
          this.dialogoRestriccion = true;
        }, 200);
      }
    },
    /**
     * Cambia el estado del registro dependiendo del tipo de restricción.
     */
    cambiarEstado() {
      if (this.tipoRestriccion === 0) {
        this.estadoContraindicacion();
      } else if (this.tipoRestriccion === 1) {
        this.estadoDuplicidad();
      } else {
        this.estadoRestriccion();
      }
    },
    /**
     * Agrega una nuevo registro dependiendo del tipo de restricción.
     */
    agregarCombinacion() {
      if (this.tipoRestriccion === 0) {
        this.agregarContraindicacion();
      } else if (this.tipoRestriccion === 1) {
        this.agregarDuplicidad();
      } else {
        this.agregarRestriccion();
      }
    },
    /** 
     * Edita un registro dependiendo del tipo de restricción.
     */
    editarCombinacion() {
      if (this.tipoRestriccion === 0) {
        this.editarContraindicacion();
      } else if (this.tipoRestriccion === 1) {
        this.editarDuplicidad();
      } else {
        this.editarRestriccion();
      }
    },
    /**
     * Valida la existencia de un registro dependiendo del tipo de restricción.
     */
    validarExistencia() {
      if (this.tipoRestriccion === 0) {
        this.validarContraindicacion();
      } else if (this.tipoRestriccion === 1) {
        this.validarDuplicidad();
      } else {
        this.validarRestriccion();
      }
    },
    /**
     * Valida que los campos idPrimerProducto y idSegundoProducto no sean los mismos.
     */
    validarProductos() {
      if (this.crearCombinacion.idPrimerProducto && this.crearCombinacion.idSegundoProducto && this.tipoRestriccion !== 2) {
        if (this.crearCombinacion.idPrimerProducto === this.crearCombinacion.idSegundoProducto) {
          this.validateProducto = "Productos a parametrizar iguales. Seleccione otro producto.";
        } else {
          this.validateProducto = "";
        }
      }
    },

    // Métodos de contraindicaciones
    /**
     * Lista las combinaciones contraindicaciones por ciertos parámetros de búsqueda.
     */
    listarContraindicaciones() {
      this.tabla.contraindicaciones.loading = true;
      this.$http.get(`/msa-setting/api/combinacionContraindicacion/list`, {
        params: {
          idPrimerProducto: this.buscar.contraindicacion.codigoUno,
          idSegundoProducto: this.buscar.contraindicacion.codigoDos,
          idEmpresa: this.enterprise.code,
          page: this.tabla.contraindicaciones.page - 1,
          size: this.tabla.contraindicaciones.itemsPerPage,
        }
      }).then((response) => {
        this.tabla.contraindicaciones.items = response.data.body.content;
        this.tabla.contraindicaciones.totalElements = response.data.body.totalElements;
        this.tabla.contraindicaciones.loading = false;
      }).catch((error) => {
        console.log(error);
        this.tabla.contraindicaciones.loading = false;
      })
    },
    /**
     * Agrega una nueva combinación contraindicación.
     */
    agregarContraindicacion() {
      const contraindicacion = {
        idPrimerProducto: {
          idProducto: this.crearCombinacion.idPrimerProducto
        },
        idSegundoProducto: {
          idProducto: this.crearCombinacion.idSegundoProducto
        },
        empresa: {
          idEmpresa: this.enterprise.code
        },
        observacion: this.crearCombinacion.observacion,
        eliminado: false
      }
      this.$http.post(`msa-setting/api/combinacionContraindicacion/add`, contraindicacion)
      .then(() => {
        this.listarContraindicaciones();
        this.clear();
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * Edita una combinación contraindicación existente.
     */
    editarContraindicacion() {
      const contraindicacion = {
        idContraindicacion: this.combinacionSeleccionada.idContraindicacion,
        idPrimerProducto: {
          idProducto: this.crearCombinacion.idPrimerProducto
        },
        idSegundoProducto: {
          idProducto: this.crearCombinacion.idSegundoProducto
        },
        empresa: {
          idEmpresa: this.enterprise.code
        },
        observacion: this.crearCombinacion.observacion,
        eliminado: false,
      }
      this.$http.post(`msa-setting/api/combinacionContraindicacion/add`, contraindicacion)
      .then(() => {
        this.listarContraindicaciones();
        this.clear();
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * Cambia el estado de una combinación contraindicación(activo o inactivo).
     */
    estadoContraindicacion() {
      const id = this.combinacionSeleccionada.idContraindicacion;
      const estado = this.combinacionSeleccionada.eliminado === false ? true : false;
      this.$http.put(`msa-setting/api/combinacionContraindicacion/activarDesactivar?id=${id}&eliminado=${estado}`)
      .then(() => {
        this.listarContraindicaciones();
        this.clear();
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * Valida la existencia de una combinación contraindicacion en la base de datos.
     */
    validarContraindicacion() {
      if (this.crearCombinacion.idPrimerProducto && this.crearCombinacion.idSegundoProducto) {
        const contraindicacion = {
          idPrimerProducto: {
            idProducto: this.crearCombinacion.idPrimerProducto
          },
          idSegundoProducto: {
            idProducto: this.crearCombinacion.idSegundoProducto
          },
          empresa: {
            idEmpresa: this.enterprise.code
          },
          observacion: this.crearCombinacion.observacion,
          eliminado: false,
        }
        this.$http.post(`msa-setting/api/combinacionContraindicacion/validar`, contraindicacion)
        .then((response) => {
          if (response.data.body !== '') {
            this.validateCodigos = 'El registro ya existe.';
          } else {
            this.validateCodigos = '';
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    },

    // Métodos de duplicidad
    /**
     * Lista las combinaciones duplicidades por ciertos parámetros de búsqueda.
     */
    listarDuplicidad() {
      this.tabla.duplicidad.loading = true;
      this.$http.get(`msa-setting/api/contraindicacionDuplicidad/list`, {
        params: {
          idPrimerProducto: this.buscar.duplicidad.codigoUno,
          idSegundoProducto: this.buscar.duplicidad.codigoDos,
          idEmpresa: this.enterprise.code,
          page: this.tabla.duplicidad.page - 1,
          size: this.tabla.duplicidad.itemsPerPage,
        }
      }).then((response) => {
        this.tabla.duplicidad.items = response.data.body.content;
        this.tabla.duplicidad.totalElements = response.data.body.totalElements;
        this.tabla.duplicidad.loading = false;
      }).catch((error) => {
        console.log(error);
        this.tabla.duplicidad.loading = false;
      })
    },
    /**
     * Agrega una nueva combinación duplicidad.
     */
    agregarDuplicidad() {
      const duplicidad = {
        idPrimerProducto: {
          idProducto: this.crearCombinacion.idPrimerProducto
        },
        idSegundoProducto: {
          idProducto: this.crearCombinacion.idSegundoProducto
        },
        empresa: {
          idEmpresa: this.enterprise.code
        },
        mensaje: this.crearCombinacion.observacion,
        eliminado: false,
      }
      this.$http.post(`msa-setting/api/contraindicacionDuplicidad/add`, duplicidad)
      .then(() => {
        this.listarDuplicidad();
        this.clear();
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * Edita una combinación duplicidad existente.
     */
    editarDuplicidad() {
      const duplicidad = {
        idDuplicidad: this.combinacionSeleccionada.idDuplicidad,
        idPrimerProducto: {
          idProducto: this.crearCombinacion.idPrimerProducto
        },
        idSegundoProducto: {
          idProducto: this.crearCombinacion.idSegundoProducto
        },
        empresa: {
          idEmpresa: this.enterprise.code
        },
        mensaje: this.crearCombinacion.observacion,
        eliminado: false,
      }
      this.$http.post(`msa-setting/api/contraindicacionDuplicidad/add`, duplicidad)
      .then(() => {
        this.listarDuplicidad();
        this.clear();
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * Cambia el estado de una combinación duplicidad(activo o inactivo).
     */
    estadoDuplicidad() {
      const id = this.combinacionSeleccionada.idDuplicidad;
      const estado = this.combinacionSeleccionada.eliminado === false ? true : false;
      this.$http.put(`msa-setting/api/contraindicacionDuplicidad/activarDesactivar?id=${id}&eliminado=${estado}`)
      .then(() => {
        this.listarDuplicidad();
        this.clear();
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * Valida la existencia de una combinación duplicidad en la base de datos.
     */
    validarDuplicidad() {
      if (this.crearCombinacion.idPrimerProducto && this.crearCombinacion.idSegundoProducto) {
        const duplicidad = {
          idPrimerProducto: {
            idProducto: this.crearCombinacion.idPrimerProducto
          },
          idSegundoProducto: {
            idProducto: this.crearCombinacion.idSegundoProducto
          },
          empresa: {
            idEmpresa: this.enterprise.code
          },
        }
        this.$http.post(`msa-setting/api/contraindicacionDuplicidad/validar`, duplicidad)
        .then((response) => {
          if (response.data.body !== '') {
            this.validateCodigos = 'El registro ya existe.';
          } else {
            this.validateCodigos = '';
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    },

    // Métodos de restricciones
    /**
     * Lista las restricciones por ciertos parámetros de búsqueda.
     */
    listarRestricciones() {
      this.tabla.restricciones.loading = true;
      this.$http.get(`msa-setting/api/restriccion/list`, {
        params: {
          page: this.tabla.restricciones.page - 1,
          size: this.tabla.restricciones.itemsPerPage,
          codigoProducto: this.buscar.restriccion.codigo,
          idEmpresa: this.enterprise.code
        }
      }).then(response => {
        this.tabla.restricciones.items = response.data.body.content;
        this.tabla.restricciones.totalElements = response.data.body.totalElements;
        this.tabla.restricciones.loading = false;
      }).catch((error) => {
        console.log(error);
        this.tabla.restricciones.loading = false;
      })
    },
    /**
     * Agrega una nueva restricción.
     */
    agregarRestriccion() {
      const restriccion = {
        empresa: {
          idEmpresa: this.enterprise.code
        },
        producto: {
          idProducto: this.crearRestriccion.producto
        },
        restriccion: this.crearRestriccion.restriccion,
        edadMinima: this.crearRestriccion.edadMinima === 0 ? null : this.crearRestriccion.edadMinima,
        edadMaxima: this.crearRestriccion.edadMaxima === 0 ? null : this.crearRestriccion.edadMaxima,
        generoValue: this.crearRestriccion.genero,
        unidadesMaximaMes: this.crearRestriccion.unidadesMaximas,
        autorizaPafi: this.crearRestriccion.pafi,
        requiereMipres: this.crearRestriccion.mipres,
        eliminado: false
      }
      this.$http.post(`msa-setting/api/restriccion/add`, restriccion)
      .then(() => {
        this.listarRestricciones();
        this.clear();
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * Edita una restricción existente.
     */
    editarRestriccion() {
      const restriccion = {
        idRestriccion: this.restriccionSeleccionada.idRestriccion,
        empresa: {
          idEmpresa: this.enterprise.code
        },
        producto: {
          idProducto: this.crearRestriccion.producto
        },
        restriccion: this.crearRestriccion.restriccion,
        edadMinima: this.crearRestriccion.edadMinima,
        edadMaxima: this.crearRestriccion.edadMaxima,
        generoValue: this.crearRestriccion.genero,
        unidadesMaximaMes: this.crearRestriccion.unidadesMaximas,
        autorizaPafi: this.crearRestriccion.pafi,
        requiereMipres: this.crearRestriccion.mipres,
        eliminado: false
      }
      this.$http.post(`msa-setting/api/restriccion/add`, restriccion)
      .then(() => {
        this.listarRestricciones();
        this.clear();
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * Cambia el estado de una restricción(activo o inactivo).
     */
    estadoRestriccion() {
      const id = this.combinacionSeleccionada.idRestriccion;
      const eliminado = this.combinacionSeleccionada.eliminado === false ? true : false;
      this.$http.put(`msa-setting/api/restriccion/activarDesactivar?id=${id}&eliminado=${eliminado}`)
      .then(() => {
        this.listarRestricciones();
        this.clear();
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * Valida la existencia de una restricción en la base de datos.
     */
    validarRestriccion() {
      this.validateCodigos = '';
      if (this.crearRestriccion.producto && (this.crearRestriccion.producto !== this.restriccionSeleccionada.producto.idProducto)) {
        const restriccion = {
          producto: {
            idProducto: this.crearRestriccion.producto
          },
          empresa: {
            idEmpresa: this.enterprise.code
          },
        }
        this.$http.post(`msa-setting/api/restriccion/validar`, restriccion)
        .then((response) => {
          if (response.data.body !== '') {
            this.validateCodigos = 'El registro ya existe.';
          } else {
            this.validateCodigos = '';
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    },
    /**
     * Abre dialogo para visualizar los detalles de la restricción.
     * @param item - Los datos de la restricción seleccionada.
     */
    abrirDialogoDetalles(item) {
      this.restriccionSeleccionada = item;
      this.dialogoDetalles = true;
    },
    /**
     * Calcula los meses ingresados para sacar los años y los meses restantes.
     * @param meses - Los meses a convertir.
     */
    convertirMeses(meses) {
      const años = Math.floor(meses / 12);
      const mesesRestantes = meses % 12;
      if (años > 1) {
        return mesesRestantes > 0 ? `(${años} años ${mesesRestantes} meses)` : `(${años} años)`;
      } else if (años === 1) {
        return mesesRestantes > 0 ? `(${años} año ${mesesRestantes} meses)` : `(${años} año)`;
      } else {
        return mesesRestantes > 0 ? `(${mesesRestantes} meses)` : '';
      }
    },
    /**
     * Sincroniza la edad mínima en meses a años.
     */
    sincronizarEdadMinima() {
      const meses = parseInt(this.crearRestriccion.edadMinima) || null;
      this.edadMinima = Math.floor(meses / 12);
    },
    /**
     * Sincroniza la edad mínima en años a meses.
     */
    sincronizarEdadMinimaAnios() {
      const años = parseInt(this.edadMinima) || null;
      this.crearRestriccion.edadMinima = años * 12;
    },
    /**
     * Sincroniza la edad máxima en meses a años.
     */
    sincronizarEdadMaxima() {
      const meses = parseInt(this.crearRestriccion.edadMaxima) || null;
      this.edadMaxima = Math.floor(meses / 12);
    },
    /**
     * Sincroniza la edad máxima en años a meses.
     */
    sincronizarEdadMaximaAnios() {
      const años = parseInt(this.edadMaxima) || null;
      this.crearRestriccion.edadMaxima = años * 12;
    },

    // Métodos de los diagnósticos(CIE)
    /**
     * Abre el diálogo para parametrizar los diagnósticos en una restricción.
     * @param item - La restricción seleccionada.
     */
    abrirDialogoDiagnosticos(item) {
      this.restriccionSeleccionada = item;
      this.producto = `${item.producto.codigoProducto} - ${item.producto.descripcionProducto}`;
      this.dialogoDiagnosticos = true;
    },
    /**
     * Agrega un diagnóstico a la lista de diagnósticos seleccionados y valida su existencia en la base de datos.
     * @param item - El diagnóstico seleccionado.
     */
    agregarDiagnostico(item) {
      if (item && !this.selectedItems.some(i => i.value === item.value)) {
        this.selectedItems.push(item);
        this.validarExistenciaCie(this.selectedItems);
        this.selectedItem = null;
      }
    },
    /**
     * Elimina un diagnóstico de la lista de diagnósticos seleccionados.
     * @param index - El índice del diagnóstico que se desea eliminar de la lista.
     */
    quitarDiagnostico(index) {
      this.selectedItems.splice(index, 1);
      this.validarExistenciaCie(this.selectedItems);
    },
    /**
     *  Busca y lista los códigos CIE basados en el término de búsqueda proporcionado.
     * @param search - El término de búsqueda ingresado.
     */
    listarCodigosCie(search) {
      const busqueda = search;
      let codigoCIE = '';
      let descripcionCIE = '';

      if (busqueda === null) {
        codigoCIE = '';
        descripcionCIE = '';
      } else {
        const esCodigoCIE = /^[A-Za-z]\d{3}$/.test(busqueda);
        if (esCodigoCIE) {
          codigoCIE = busqueda;
        } else {
          descripcionCIE = busqueda;
        }
      }
      this.$http.get(`msa-setting/api/cie/list`, {
        params: {
          page: 0,
          size: 10,
          codigoCIE: codigoCIE,
          descripcionCIE: descripcionCIE,
        }
      }).then((response) => {
        if (response.data.body.content.length > 0) {
          response.data.body.content.forEach((item) => {
            this.diagnosticos.push({
              text: `${item.codigoCIE} - ${item.descripcionCIE}`,
              value: item.codigoCIE
            });
          });
        }
      }).catch ((error) => {
        console.log(error);
      })
    },
    /**
     * Abre un diálogo para ver los diagnósticos parametrizados a una restricción.
     * @param item - La restricción seleccionada.
     */
    abrirDialogoRestriccionCie(item) {
      this.restriccionSeleccionada = item;
      this.producto = `${item.producto.codigoProducto} - ${item.producto.descripcionProducto}`;
      this.listarRestriccionCie();
      this.dialogoRestriccionCie = true;
    },
    /**
     * Lista los diagnosticos relacioneados a una restricción específica.
     */
    listarRestriccionCie() {
      this.tabla.restriccionCie.loading = true;
      this.$http.get(`msa-setting/api/restriccioncie/list`, {
        params: {
          page: this.tabla.restriccionCie.page - 1,
          size: this.tabla.restriccionCie.itemsPerPage,
          idRestriccion: this.restriccionSeleccionada.idRestriccion,
          estadoCie: this.buscar.cie.tipoDiagnostico
        }
      }).then(response => {
        this.tabla.restriccionCie.items = response.data.body.content;
        this.tabla.restriccionCie.totalElements = response.data.body.totalElements;
        this.tabla.restriccionCie.loading = false;
      }).catch((error) => {
        console.log(error);
        this.tabla.restriccionCie.loading = false;
      })
    },
    /**
     * Agrega diagnósticos a una restricción.
     */
    agregarRestriccionCie() {
      const list = this.selectedItems.map((codigo) => ({
        restriccion: {
          idRestriccion: this.restriccionSeleccionada.idRestriccion
        },
        cie: {
          codigoCIE: codigo.value
        },
        estadoCie: this.tipoDiagnostico,
        eliminado: false
      }));
      this.$http.post('msa-setting/api/restriccioncie/add', list)
      .then(() => {
        this.listarRestricciones();
        this.clear();
      }).catch((error) => {
        console.error('Error al agregar la restricción CIE:', error);
      });
    },
    /**
     * Edita el diagnóstico de una restricción.
     */
    actualizarRestriccionCie() {
      const restriccionCie = {
        idUsem: this.restriccionCieSeleccionada.idUsem,
        restriccion: {
          idRestriccion: this.restriccionCieSeleccionada.restriccion.idRestriccion
        },
        cie: {
          codigoCIE: this.editarRestriccionCie.codigo
        },
        estadoCie: this.editarRestriccionCie.tipo,
        eliminado: false
      };
      this.$http.post('msa-setting/api/restriccioncie/add', [restriccionCie])
      .then(() => {
        this.dialogoEditarRestriccionCie = false;
        this.listarRestriccionCie();
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * Abre un diálogo para editar el diagnóstico de una restricción en específico.
     * @param item - La restricción seleccionada.
     */
    abrirDialogoEditarRestriccionCie(item) {
      this.restriccionCieSeleccionada = item;
      this.editarRestriccionCie.tipo = item.estadoCie;
      this.editarRestriccionCie.codigo = item.cie.codigoCIE;
      this.listarCodigosCie(item.cie.codigoCIE);
      this.dialogoEditarRestriccionCie = true;
    },
    /**
     * Abre un diálogo para cambiar el estado de un diagnosticó relacionado a una restricción.
     * @param item - La restricción seleccionada.
     */
    abrirDialogoEstadoRestriccionCie(item) {
      this.restriccionCieSeleccionada = item;
      this.dialogoEstadoRestriccionCie = true;
      this.estadoRestriccionCie = this.restriccionCieSeleccionada.eliminado === false ? '¿Desea inactivar el registro?' : '¿Desea activar el registro?';
    },
    /**
     * Cambia el estado de un diagnosticó relacionado a una restricción(activa o inactiva).
     */
    cambiarEstadoRestriccionCie() {
      const id = this.restriccionCieSeleccionada.idUsem;
      const eliminado = this.restriccionCieSeleccionada.eliminado === false ? true : false;
      this.$http.get(`msa-setting/api/restriccioncie/activarDesactivar?id=${id}&eliminado=${eliminado}`)
      .then(() => {
        this.listarRestriccionCie();
        this.dialogoEstadoRestriccionCie = false;
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * Valida la existencia de un diagnóstico en la restricción seleccionada al editar.
     */
    validarExistenciaCieEditar() {
      if (this.editarRestriccionCie.codigo) {
        const restriccionCie = {
          restriccion: {
            idRestriccion: this.restriccionSeleccionada.idRestriccion
          },
          cie: {
            codigoCIE: this.editarRestriccionCie.codigo
          },
        };
        this.$http.post('msa-setting/api/restriccioncie/validar', restriccionCie)
        .then((response) => {
          if (response.data.body !== '') {
            this.validateCodigos = 'El registro ya existe.';
          } else {
            this.validateCodigos = '';
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    },
    /** 
     * Valida la existencia de un diagnóstico en la restricción seleccionada al crear.
     */
    validarExistenciaCie() {
      this.errorCodigos = [];
      const requests = this.selectedItems.map(item => {
        const restriccionCie = {
          restriccion: {
            idRestriccion: this.restriccionSeleccionada.idRestriccion
          },
          cie: {
            codigoCIE: item.value
          }
        };
        return this.$http.post('msa-setting/api/restriccioncie/validar', restriccionCie);
      });

      Promise.all(requests)
        .then((responses) => {
          responses.forEach((response, index) => {
            if (response.data.body !== '') {
              this.errorCodigos.push(this.selectedItems[index].value);
            }
          });
        }).catch((error) => {
          console.error('Error al validar la existencia del CIE:', error);
        });
    },
    clear() {
      this.dialogoCombinacion = false;
      this.dialogoCambiarEstado = false;
      this.dialogoRestriccion = false;
      this.dialogoDetalles = false;
      this.dialogoDiagnosticos = false;
      this.dialogoRestriccionCie = false;
      this.dialogoEditarRestriccionCie = false;
      this.dialogoEstadoRestriccionCie = false;
      this.combinacionSeleccionada = {};
      this.restriccionSeleccionada = {};
      this.restriccionCieSeleccionada = {};
      setTimeout(() => {
        // limpiar variables de combinaciones
        this.crearCombinacion.idPrimerProducto = null;
        this.crearCombinacion.idSegundoProducto = null;
        this.crearCombinacion.observacion = null;
        this.productosUno = [];
        this.productosDos = [];
        this.editar = false;
        this.validateProducto = "";
        this.validateCodigos = "";
        if (this.$refs.crearCombinacion) {
          this.$refs.crearCombinacion.reset();
        }

        // limpiar variables de restricción
        this.crearRestriccion.producto = null;
        this.crearRestriccion.restriccion = null;
        this.crearRestriccion.mipres = null;
        this.crearRestriccion.edadMinima = null;
        this.crearRestriccion.edadMaxima = null;
        this.crearRestriccion.diagnosticosPermitidos = null;
        this.crearRestriccion.diagnosticosRestrictivos = null;
        this.crearRestriccion.unidadesMaximas = null;
        this.crearRestriccion.pafi = null;
        this.crearRestriccion.genero = null;
        this.edadMinima = null;
        this.edadMaxima = null;
        this.validateCodigos = '';
        if (this.$refs.crearRestriccion) {
          this.$refs.crearRestriccion.reset();
        }

        // Limpiar variables del CIE
        this.tipoDiagnostico = 'P';
        this.errorCodigos = [];
        this.buscar.cie.tipoDiagnostico = 'P';
        this.selectedItem = null;
        this.selectedItems = [];
        if (this.$refs.editarRestriccionCie) {
          this.$refs.editarRestriccionCie.reset();
        }
        if (this.$refs.buscarCie) {
          this.$refs.buscarCie.reset();
        }
      }, 500)
    },
    clearEditar() {
      this.dialogoEditarRestriccionCie = false;
      setTimeout(() => {
        if (this.$refs.editarRestriccionCie) {
          this.$refs.editarRestriccionCie.reset();
        }
      }, 500)
      this.validateCodigos = '';
    }
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 86vh;
}

::v-deep .v-tabs-slider-wrapper {
  top: 85% !important;
  height: 1.99;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

::v-deep #textarea {
  margin: 5px;
}

.campo {
  width: 50%;
}

.contenedor-lista {
  border: rgb(158 158 158) 1px solid;
  margin-bottom: 10px;
}

.lista {
  max-height: 260px;
  overflow-y: auto;
  min-height: 40px;
}

</style>